* {
    box-sizing: border-box;
    margin: 0;
    font-family: 'Nunito',sans;

  }
  .loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
  a {
    color: #666;
    font-size: 14px;
    display: block;
    cursor: pointer;
  }
  .login-title {
    text-align: center;
  }
  #login-page {
    display: flex;
  }
  .notice {
    font-size: 13px;
    text-align: center;
    color: #666;
  }
  .login {
    width: 40%;
    height: 100vh;
    background: #FFF;
    padding: 70px;
  }
  .login a {
    margin-top: 25px;
    text-align: center;
    cursor: pointer;
  }
  .form-login {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
  }
  .form-login label {
    text-align: left;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 20px;
    display: block;
    color: #666;
  }
  .input-email,
  .input-password {
    width: 100%;
    background: #ededed;
    border-radius: 12px;
    margin: 4px 0 10px 0;
    padding: 10px;
    display: flex;
  }
  .icon {
    padding: 4px;
    color: #666;
    min-width: 30px;
    text-align: center;
  }
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 4px 0;
    outline: none;
  }
  .btnadlogin {
    width: 100%;
    border: 0;
    border-radius: 12px;
    padding: 14px;
    background: #008552;
    color: #FFF;
    display: inline-block;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    transition: ease all 0.3s;
  }
  .btnadlogin:hover {
    opacity: 0.9;
  }
  .background {
    width: 60%;
    padding: 40px;
    height: 100vh;
    background: linear-gradient(60deg, rgba(22, 173, 170, 0.5), rgba(0, 133, 82, 0.7)), url('../../../assets/cklogin.jpeg') center no-repeat;
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    align-content: center;
    flex-direction: row;
  }
  .background h1 {
    font-family: 'Nunito', sans;
    max-width: 800px;
    color: #cee670;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  .background p {
    max-width: 650px;
    color: #1a1a1a;
    font-size: 15px;
    text-align: right;
    padding: 0;
    margin: 15px 0 0 0;
  }
  .created {
    margin-top: 40px;
    text-align: center;
  }
  .created p {
    font-size: 13px;
    font-weight: bold;
    color: #008552;
  }
  .created a {
    color: #666;
    font-weight: normal;
    text-decoration: none;
    margin-top: 0;
  }
  /* .checkbox label {
    display: inline;
    margin: 0;
  } */

  .danger{
    color: #e50a0af5;
    font-size: 13px;

  }


@media(max-width: 575.98px) {
    .login {
        width: 100%;
        height: 100vh;
        background: #FFF;
        padding: 70px;
      }
      .background{
        display: none;
      }
   
}  