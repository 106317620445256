:root{
  --header-height:3rem;
  --nav-width:68px;
  --color--deep-bg:#794b15;
  --first-color:#D3B613;
  --first-color-alt:#e6d470;
  --white-color:#8dbc8a;
  --blue-color:#4285F4;
  --red-color:#DB4437;
  --green-color:#0F9D58;
  --yellow-color:#F4B400;

  --btn-color:#6d859e;
  --color-bg2:#1e81b0;
  --color-bg3:#e28743;


  --transition-type:0.5s;
}

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

a{
  text-decoration: none;
}

ul{
  list-style: none;
}
main{
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding: 0 1rem;
  font-size: 1rem;
  font-family: 'Nunito Sans', sans-serif;
  transition: var(--transition-type);
}
/* .header{
  position: fixed;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--green-color);
  padding: 0 1rem;
  transition: var(--transition-type);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

} */
.header-toggle{
  font-size: 1.25rem;
  cursor: pointer;
  color: var(--btn-color);
}

.sidebar{
  position: fixed;
  top: 0;
  left: -30%;
  height: 100vh;
  width: var(--nav-width);
  background-color: var(--blue-color);
  padding-top: 1rem;
  transition: var(--transition-type);
}
.nav{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  height: 100%;
}
.nav-logo, 
.nav-link{
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 2rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav-logo{
 margin-bottom: 2rem;
}
/* .nav-logo img{
  width: 100px;
  height: 80px;
} */
.nav-logo-icon{
  font-size: 1.25rem;
  color: var(--btn-color);
  cursor: pointer;
}

.nav-logo-name{
  font-weight: 700;
  color: var(--white-color);
}
.nav-link{
  position: relative;
  color: var(--first-color-alt);
  transition: var(--transition-type);
  margin-bottom: 1.5rem;
}
.nav-link:hover{
  color: var(--white-color);
  background-color: rgba(0, 0, 0, 0.1);
}
.active{
  color: var(--white-color);
  background-color: rgba(0, 0, 0, 0.1);
}
.active::after{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 38px;
  width: 3px;
  background-color: var(--white-color);
  transition: var(--transition-type);

}


.nav-link
.nav-tooltip{
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 5px;
  right: 0%;
}


.nav-link:hover
.nav-tooltip{
  visibility: visible;
}

.show{
  left: 0;
}

.space-toggle{
  padding-left: calc(var(--nav-width) + 1rem);
}

@media screen and (min-width:768px) {
  main{
      margin: calc(var(--header-height) + 1rem) 0 0 0;
      padding: 0 1rem 0 calc(var(--nav-width) + 2rem);
  }
  .header{
      height: calc(var(--header-height) + 1rem);
      padding-left: calc(var(--nav-width) + 2rem);
  }
  .sidebar{
      left: 0;
      padding: 1rem 0 0 0;
  }
  .show{
      width: calc(var(--header-height) + 156px);
  }
  .space-toggle{
      padding-left: calc(var(--nav-width) + 188px);
  }
}