.shead{
    position: sticky;
    top: 0;
    display: flex;
    z-index: 9899999999999;
    background: #046b759c;
    width: 100wv;
    height: 50px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;;
  }

  h2{
    font-size: 30px;
    color: rgb(9, 56, 40);
}
  .shead i{
      color: #0b9ba5;
      border-radius: 10px;
      font-size: 20px;
  }

  .templateContainer{
    margin: 3% 0;
}
.templateContainer > .searchInput_Container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: sticky;
    top: 50px;
}
.templateContainer .searchInput_Container input{
    width: 80%;
    height: 40px;
    padding: 10px;
    outline: none;
    font-size: 30px;
    border-radius: 10px;

}
.templateContainer .template_Container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;
}
.templateContainer .template_Container .template{
    background-color: #b6f8f6;
    margin: 30px;
    padding: 0px 20px;
    border-radius: 10px;

}
.templateContainer .template_Container .template img{
    height: 150px;
    width: 100%;
}

.templateContainer .template_Container .template i{
    padding-top: 10px;
    color: #5fb0df;
    font-size: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


h3{
    font-weight: 500;
    text-align: center;
    font-size: 20px;
    font-family: 'Nunito', sans;
}

.cat{
    font-weight: 900;
    font-size: 18px;
    color: #043522;
    display: flex;
    justify-content: center;
 
}
.price{
    font-weight: 900;
    text-align: center;
}
/* small{
    font-weight: 900;
} */

.template .status{
    margin-top: 2%;
}

.btnstatus{
    background-color: #013e2f;
    color: #f1f1d1;
    width: 80px;
    height: 25px;
    letter-spacing: 5px;
    border: 1px solid #011e16;
    border-radius: 8px;
}
.btnstatus:hover{
    background-color:#f1f1d1 ;
    color: #013e2f;
    width: 90px;
    height: 25px;
    letter-spacing: 5px;
    border: 1px solid #011e16;
    border-radius: 8px;
    transition: .5s all ease-in-out;
}
.session{
    font-size: 1rem;
    display: inline-block;
    color: #944d12;
    vertical-align: middle;
    margin-bottom: 0;
    padding-left: 20px;
    margin-top: -1%;
}