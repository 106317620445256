

.header{
  position: fixed;
  top: 0;
  left: 0;
  height: var(--header-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--btn-color);
  padding: 0 1rem;
  transition: var(--transition-type);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);

} 

.header-link{
  padding-left: 1.4rem;
  color: #fff;
}
.nav-link-name{
  padding-left: 0.2rem;
  color: #fff;
  font-size: 13px;
}

i{
  /* color: var(--plain-white) ; */
  color: #c7c6c6;
}



.header-link
.nav-tooltip{
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 60px;
  right: 0%;
}


.header-link:hover
.nav-tooltip{
  visibility: visible;
}