.widgetLg {
    flex: 4;
    -webkit-box-shadow: 0px 0px 15px -10px #ded9d9bf;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      padding: 20px;
    width: 100%;
  }
  
  .widgetLgTitle {
    font-size: 22px;
    font-weight: 600;
  }
  
  .widgetLgTable{
    width: 100%;
    border-spacing: 20px;
  }
  
  .widgetLgTh{
    text-align: left;
  }
  
  .widgetLgUser{
    display: flex;
    align-items: center;
    font-weight: 600;
  }
  
  .widgetLgImg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .widgetLgDate,.widgetLgAmount{
    font-weight: 300;
  }
  
  .widgetLgButton {
    padding: 5px 7px;
    border: none;
    border-radius: 10px;
  }
  
  .widgetLgButton.Approved{
      background-color: #e5faf2;
      color: #3bb077;
  }
  .widgetLgButton.Declined{
      background-color: #fff0f1;
      color: #d95087;
  
  }
  .widgetLgButton.Pending{
      background-color: #ebf1fe;
      color: #2a7ade;
  
  }

  .btn-approve{
    width:100px;
    height: 30px;
    font-family:'Nunito', sans;
    border:none;
     border-radius:5px;
     background-color:#136c69;
     color: #e5faf2;
    
  }
  .btn-approve:hover{
    width:110px;
    height: 30px;
    font-family:'Nunito', sans;
    border:none;
    border-radius:5px;
    background-color:#1e938f;
    transition: all .1s ease-in-out;
    cursor: pointer;
  }

  .btn-decline{
    width:100px;
    height: 30px;
    font-family:'Nunito', sans;
    border:none;
     border-radius:5px;
     background-color:#ed790c;
     color: #e5faf2;
     margin-left: 10px ;
  }
  .btn-decline:hover{
    width:110px;
    height: 30px;
    font-family:'Nunito', sans;
    border:none;
    border-radius:5px;
    background-color:#e1930b;
    transition: all .1s ease-in-out;
    cursor: pointer;
  }

     /*Responsive*/
     @media screen and (max-width: 600px) {
      .widgetLg {
        justify-content: center;
        flex-wrap: wrap;
        margin: 0 auto 0;
        /* margin: 0px 10px; */
        width: 100%;
  
      }
    
    }