 .home{
    flex: 4;
    background-color: #ffffff;
    font-family: 'Nunito', sans;
} 

.homeWidgets{
    display: flex;
    margin: 10px;
}

 /*Responsive*/
 @media screen and (max-width: 600px) {
    .home {
        display: grid;
      /* flex-wrap: wrap; */
      margin: 0 auto 0;
      /* margin: 0px 10px; */
      width: 100%;
    }
  
  }