* {
    box-sizing: border-box;
  }
  /* Float four columns side by side */
  .calumn {
    float: left;
    width: 33.3%;
    padding: 0 10px;
    height: 30%;
    cursor: pointer;
  }
  /* Remove extra left and right margins, due to padding in columns */
  .raw {
    width: 100%;
}
  
  /* Clear floats after the columns */
  .raw:after {
    content: "";
    display: table;
    clear: both;
  }
  
  /* Style the counter cards */
  .cord {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
    padding: 16px;
    text-align: center;
    background-color: #f1f1f1;
    height: 150px;
    border-radius: 8px;
  }


  .cd1{
    background-color: #e672ea;
    color: #010101;
  }
  .cd2{
    background-color: #61ede8;
    color: #010101;
  }

  .cd3{
    background-color: hwb(195 38% 7%);
    color: #010101;
  }

  .fig{
    color: #000;
    font-size: 30px;
    margin-top: 10px;
  }
  .hdng{
    color: #000;
    font-size: 20px;
  }
  .dt{
    font-size: 10px;
  }
  
  /* Responsive columns - one column layout (vertical) on small screens */
  @media screen and (max-width: 600px) {
    .calumn {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }